$(function() {
  // hide loading modal and show all content once everything is loaded
  $('#loader').hide();
  $('main').removeClass('hidden');
  $('#main-content').removeClass('hidden');

  // ensures that the floating wizard stays by the sidebar whether it's collapsed or not
  let sidebar_width = $('#sidebar').width();
  $('#wizard-collapser').css('left', `calc(${sidebar_width}px - 70px)`);

  // event listener for mini wizard
  $('.nav-wizard a').on('click', (t) => {
    let target = t.currentTarget;
    let id = target.id;

    $('.nav-wizard a.active').addClass('completed');
    $('.nav-wizard a.active').attr('aria-selected', 'false');
    $('.nav-wizard a.active').removeClass('active');
    $(`#${id}`).attr('aria-selected', 'true');

    let href = target.getAttribute('href');
    $('.mini-wizard-collapse-content a.link').removeClass('active');
    $('.mini-wizard-collapse-content a.lin k').attr('aria-selected', 'false');
    $(`.mini-wizard-collapse-content a.link[href="${href}"]`).addClass('active');
    $(`.mini-wizard-collapse-content a.link[href="${href}"]`).attr('aria-selected', 'true');
  });

  // collapses the sidebar
  $('#sidebar-collapser-2').on('click', () => {
    $('#sidebar').toggleClass('collapsed');
    if ($('#sidebar').hasClass('collapsed')) {
      $('#sidebar-collapser .text').html("");
      $('#sidebar-collapser .icon').html(
        '<i class="fas fa-arrow-right"></i>');
      set_sidebar_state('collapsed');
      $('#wizard-collapser').css('left', 0);
    } else {
      $('#sidebar-collapser .text').html("Collapse");
      $('#sidebar-collapser .icon').html(
        '<i class="fas fa-arrow-left"></i>');
      $('#collapseEvidences').addClass("collapse show");
      $('#wizard-collapser').css('left', 'calc(150px - 70px)');
      set_sidebar_state('show');
    }
  });

  // collapses the sidebar
  $('#sidebar-collapser').on('click', () => {
    $('#sidebar').toggleClass('collapsed');
    if ($('#sidebar').hasClass('collapsed')) {
      $('#sidebar-collapser .text').html("");
      $('#sidebar-collapser .icon').html(
        '<i class="fas fa-arrow-right"></i>');
      $('#wizard-collapser').css('left', 0);
      set_sidebar_state('collapsed');
    } else {
      $('#sidebar-collapser .text').html("Collapse");
      $('#sidebar-collapser .icon').html(
        '<i class="fas fa-arrow-left"></i>');
      $('#collapseEvidences').addClass("collapse show");
      set_sidebar_state('show');
      $('#wizard-collapser').css('left', 'calc(150px - 70px)');
    }

  });

  $('.nav-space .nav.nav-tabs > a.nav-link.nav-item').on('click', () => {
    $('.nav-space a.nav-link.nav-item.active').toggleClass('active');
  });

  // ensures hyperlinks set in the eqa step instructions, and homepage panels opens in a new tab
  $('.eqa-step-instructions a, .trix-content a').attr('target', '_blank');

  $('button, a').on('click', (e) => {
    if ($(e.target).html() == 'Sign in with Google')
      return;
    if ($(e.target).attr('href') == '/logout')
      return;
    $.ajax({
      url: `/refresh`,
      method: 'GET',
      dataType: 'json',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
  });
});

/**
 * Sets the sidebar state by passing the state to the application controller.
 * @param  {('show'|'collapsed')} state - The current state of the sidebar.
 */
window.set_sidebar_state = (state) => {
  let payload = {
    'sidebar_state': state
  };

  $.ajax({
    url: '/sidebar_state',
    method: 'POST',
    dataType: 'json',
    data: payload,
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
  });
}